import { zCreateAnalyticCampaignTrpcInput } from '@chatai/admin-backend/src/router/analyticCampaigns/createAnalyticCampaign/input'
import { canAnalyzeChats } from '@chatai/admin-backend/src/utils/can'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'
import { Alert } from '../../../components/Alert'
import { Button } from '../../../components/Button'
import { FormItems } from '../../../components/FormItems'
import { Input } from '../../../components/Input'
import { Segment } from '../../../components/Segment'
import { Textarea } from '../../../components/Textarea'
import { useForm } from '../../../lib/form'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { getAnalyticCampaignRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'

export const AnalyticCampaignNewPage = withPageWrapper({
  checkAccess: ({ ctx }) => canAnalyzeChats(ctx.me),
  title: 'New Analytic Campaign',
})(() => {
  const navigate = useNavigate()
  const createAnalyticCampaign = trpc.createAnalyticCampaign.useMutation()
  const { formik, alertProps, buttonProps } = useForm({
    initialValues: {
      title: '',
      question: '',
      options: '',
      chatsCount: '',
    },
    validationSchema: zCreateAnalyticCampaignTrpcInput
      .omit({
        options: true,
        chatsCount: true,
      })
      .extend({
        options: z.string().min(1),
        chatsCount: z.string().min(1),
      }),
    onSubmit: async (values) => {
      const result = await createAnalyticCampaign.mutateAsync({
        ...values,
        options: values.options
          .split('\n')
          .map((x) => x.trim())
          .filter((x) => x),
        chatsCount: +values.chatsCount,
      })
      navigate(
        getAnalyticCampaignRoute({ analyticCampaignSerialNumber: result.analyticCampaign.serialNumber.toString() })
      )
    },
    resetOnSuccess: true,
    enableReinitialize: true,
  })
  return (
    <Segment title="New Analytic Campaign" helpId="analytic-campaign-new">
      <form onSubmit={formik.handleSubmit}>
        <FormItems>
          <Input label="Title" name="title" formik={formik} />
          <Input label="Chats Count" name="chatsCount" formik={formik} />
          <Textarea label="Question" name="question" formik={formik} />
          <Textarea label="Options" name="options" formik={formik} />
          <Alert {...alertProps} />
          <Button {...buttonProps}>Create Analytic Campaign</Button>
        </FormItems>
      </form>
    </Segment>
  )
})
