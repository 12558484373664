import { zGetPromptSuggestionsStatsTrpcInput } from '@chatai/admin-backend/src/router/promptSuggestions/getPromptSuggestionsStats/input'
import { canAnalyzePrompts, canSyncPrompts } from '@chatai/admin-backend/src/utils/can'
import { Alert } from '../../../components/Alert'
import { Button, Buttons, LinkButton } from '../../../components/Button'
import { FormItems } from '../../../components/FormItems'
import { Input } from '../../../components/Input'
import { Loader } from '../../../components/Loader'
import { Segment } from '../../../components/Segment'
import { useForm } from '../../../lib/form'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { trpc } from '../../../lib/trpc'
import css from './index.module.scss'

const validationSchema = zGetPromptSuggestionsStatsTrpcInput.pick({ dateFrom: true, dateTo: true, buildVersion: true })
export const PromptSuggestionsPage = withPageWrapper({
  authorizedOnly: true,
  checkAccess: ({ ctx }) => canAnalyzePrompts(ctx.me),
  title: 'Prompt Suggestions',
  setProps: ({ getAuthorizedMe }) => ({ me: getAuthorizedMe() }),
})(({ me }) => {
  const syncPrompts = trpc.syncPrompts.useMutation()

  const filterForm = useForm({
    initialValues: { dateFrom: '', dateTo: '', buildVersion: '' },
    validationSchema,
  })
  const filter = (() => {
    const maybeFilter = {
      dateFrom: filterForm.formik.values.dateFrom,
      dateTo: filterForm.formik.values.dateTo,
      buildVersion: filterForm.formik.values.buildVersion,
    }
    const parseResult = validationSchema.safeParse(maybeFilter)
    return parseResult.success ? maybeFilter : {}
  })()
  const qr = trpc.getPromptSuggestionsStats.useQuery(filter)

  const { formik, alertProps, buttonProps } = useForm({
    onSubmit: async () => {
      await syncPrompts.mutateAsync()
    },
    successMessage: 'Prompts synced',
  })

  return (
    <Segment title="Prompt Suggestions" helpId="prompt-suggestions">
      {canSyncPrompts(me) && (
        <Segment size={2} title="Sync Prompts" helpId={null}>
          <FormItems>
            <Alert {...alertProps} />
            <Buttons>
              <Button
                {...buttonProps}
                onClick={() => {
                  formik.handleSubmit()
                }}
              >
                Sync Prompts
              </Button>
              <LinkButton
                href="https://docs.google.com/spreadsheets/d/1k0LiYrXtzrmoxKdYULdpmzDR6-dij3PNvyDmIinFVPY/edit?pli=1#gid=1294108152"
                target="_blank"
              >
                View Prompts
              </LinkButton>
            </Buttons>
          </FormItems>
        </Segment>
      )}
      <Segment size={2} title="Stats" helpId={null}>
        <div className={css.filter}>
          <FormItems>
            <Input maxWidth={'100%'} label="Date From" name="dateFrom" formik={filterForm.formik} />
            <Input maxWidth={'100%'} label="Date To" name="dateTo" formik={filterForm.formik} />
            <Input maxWidth={'100%'} label="Build Version" name="buildVersion" formik={filterForm.formik} />
          </FormItems>
        </div>
        {(() => {
          if (qr.error) {
            return <Alert color="red">{qr.error.message}</Alert>
          }
          if (!qr.data) {
            return <Loader type="section" />
          }
          const promptSuggestionsStats = qr.data.promptSuggestionsStats
          return (
            <table className={css.table}>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Title</th>
                  <th>Usages</th>
                  <th>Users</th>
                </tr>
              </thead>
              <tbody>
                {promptSuggestionsStats.map((pss) => (
                  <tr key={pss.promptSuggestion.id}>
                    <td>{pss.promptSuggestion.id}</td>
                    <td>{pss.promptSuggestion.title}</td>
                    <td>{pss.usagesCount}</td>
                    <td>{pss.usersCount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )
        })()}
      </Segment>
    </Segment>
  )
})
