import { zGetAnalyticCampaignsTrpcInput } from '@chatai/admin-backend/src/router/analyticCampaigns/getAnalyticCampaigns/input'
import { canAnalyzeChats } from '@chatai/admin-backend/src/utils/can'
import format from 'date-fns/format'
import InfiniteScroll from 'react-infinite-scroller'
import { Link } from 'react-router-dom'
import { useDebounce } from 'usehooks-ts'
import { Alert } from '../../../components/Alert'
import { Input } from '../../../components/Input'
import { layoutContentElRef } from '../../../components/Layout'
import { Loader } from '../../../components/Loader'
import { Segment } from '../../../components/Segment'
import { useForm } from '../../../lib/form'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { getAnalyticCampaignRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'
import css from './index.module.scss'

export const AnalyticCampaignsPage = withPageWrapper({
  title: 'Analytic Campaigns',
  checkAccess: ({ ctx }) => canAnalyzeChats(ctx.me),
})(() => {
  const { formik } = useForm({
    initialValues: { serialNumber: '' },
    validationSchema: zGetAnalyticCampaignsTrpcInput.pick({ serialNumber: true }),
  })
  const serialNumber = useDebounce(formik.values.serialNumber, 500)
  const { data, error, isLoading, isError, hasNextPage, fetchNextPage, isFetchingNextPage, isRefetching } =
    trpc.getAnalyticCampaigns.useInfiniteQuery(
      {
        serialNumber,
      },
      {
        getNextPageParam: (lastPage) => {
          return lastPage.nextCursor
        },
      }
    )

  return (
    <Segment title="Analytic Campaigns" helpId="analytic-campaigns">
      <div className={css.filter} style={{ display: 'none' }}>
        <Input maxWidth={'100%'} label="Serial Number" name="serialNumber" formik={formik} />
      </div>
      {isLoading || isRefetching ? (
        <Loader type="section" />
      ) : isError ? (
        <Alert color="red">{error.message}</Alert>
      ) : !data.pages[0].analyticCampaigns.length ? (
        <Alert color="brown">Nothing found by search</Alert>
      ) : (
        <div className={css.analyticCampaigns}>
          <InfiniteScroll
            threshold={250}
            loadMore={() => {
              if (!isFetchingNextPage && hasNextPage) {
                void fetchNextPage()
              }
            }}
            hasMore={hasNextPage}
            loader={
              <div className={css.more} key="loader">
                <Loader type="section" />
              </div>
            }
            getScrollParent={() => layoutContentElRef.current}
            useWindow={(layoutContentElRef.current && getComputedStyle(layoutContentElRef.current).overflow) !== 'auto'}
          >
            {data.pages
              .flatMap((page) => page.analyticCampaigns)
              .map((analyticCampaign) => (
                <div className={css.analyticCampaign} key={analyticCampaign.serialNumber}>
                  <Segment
                    size={2}
                    helpId={null}
                    title={
                      <Link
                        className={css.analyticCampaignLink}
                        to={getAnalyticCampaignRoute({
                          analyticCampaignSerialNumber: analyticCampaign.serialNumber.toString(),
                        })}
                      >
                        {analyticCampaign.title}
                      </Link>
                    }
                  >
                    Status: {analyticCampaign.status}
                    <br />
                    Date: {format(analyticCampaign.createdAt, 'yyyy-MM-dd HH:mm')}
                    <br />
                    Total Chats Count: {analyticCampaign.totalChatsCount}
                    <br />
                    Pending Chats Count: {analyticCampaign.pendingChatsCount}
                    <br />
                    Succeeded Chats Count: {analyticCampaign.succeededChatsCount}
                    <br />
                    Failed Chats Count: {analyticCampaign.failedChatsCount}
                  </Segment>
                </div>
              ))}
          </InfiniteScroll>
        </div>
      )}
    </Segment>
  )
})
