import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import { Button } from '../../../components/Button'
import { FormItems } from '../../../components/FormItems'
import { Loader } from '../../../components/Loader'
import { Segment } from '../../../components/Segment'

let screamerBase64 = '/screamer.png'
let audio: HTMLAudioElement | null = null

export const HomePage = () => {
  const [scream, setScream] = useState(false)
  const [wasScreamed, setWasScreamed] = useState(false)
  const [updatesCount, setUpdatesCount] = useState(0)
  const [needUpdate, setNeedUpdate] = useState(true)
  const imageRef = useRef<HTMLImageElement | null>(null)
  // const [quote, setQuote] = useState({
  //   loading: true,
  //   author: null,
  //   text: null,
  // })
  const [image, setImage] = useState({
    loading: true,
    url: null,
    width: 0,
    height: 0,
  })
  const [imageLoaded, setImageLoaded] = useState(false)
  useEffect(() => {
    if (needUpdate) {
      setUpdatesCount((prev) => prev + 1)
    }
  }, [needUpdate])
  useEffect(() => {
    if (scream) {
      setWasScreamed(true)
    }
  }, [scream])
  useEffect(() => {
    if (updatesCount >= 4 && !wasScreamed) {
      void audio?.play()
      setScream(true)
    }
  }, [updatesCount])

  // useEffect(() => {
  //   if (!needUpdate) {
  //     return
  //   }
  //   setQuote({
  //     loading: true,
  //     author: null,
  //     text: null,
  //   })
  //   void axios.get('https://api.quotable.io/quotes/random').then((res) => {
  //     setQuote({
  //       loading: false,
  //       text: res.data[0].content,
  //       author: res.data[0].author,
  //     })
  //     setNeedUpdate(false)
  //   })
  // }, [needUpdate])
  useEffect(() => {
    if (!needUpdate) {
      return
    }
    setImage({
      loading: true,
      url: null,
      width: 0,
      height: 0,
    })
    // https://api.thecatapi.com/v1/images/search
    void axios.get('https://api.thecatapi.com/v1/images/search').then((res) => {
      setImage({
        loading: false,
        url: res.data[0].url,
        width: res.data[0].width,
        height: res.data[0].height,
      })
      setNeedUpdate(false)
    })
  }, [needUpdate])
  useEffect(() => {
    // Preload the audio
    audio = new Audio('/meow.mp3')
    audio.load()

    // Preload the screamer
    const img = new Image()
    img.crossOrigin = 'Anonymous'
    img.src = '/screamer.png'
    void new Promise((resolve) => {
      img.onload = () => {
        const canvas = document.createElement('canvas')
        canvas.width = img.width
        canvas.height = img.height
        const ctx = canvas.getContext('2d')
        ctx?.drawImage(img, 0, 0)
        const dataURL = canvas.toDataURL('image/png')
        screamerBase64 = dataURL
        resolve(dataURL)
      }
    })
  }, [])
  useEffect(() => {
    if (imageRef.current) {
      setImageLoaded(false)
      imageRef.current.onload = () => {
        setImageLoaded(true)
      }
    }
  }, [image.url])
  // const loading = quote.loading || image.loading || !quote.text || !quote.author || !image.url || !imageLoaded
  const loading = image.loading || !image.url || !imageLoaded
  return (
    <>
      {loading && <Loader type="page" />}
      {scream && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: '#000',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
          onClick={() => {
            setScream(false)
          }}
        >
          <img src={screamerBase64} alt="scream" style={{ maxWidth: '100%', height: 'auto' }} />
        </div>
      )}
      <div style={{ visibility: loading ? 'hidden' : 'visible' }}>
        <FormItems>
          <Button
            color="pink"
            onClick={() => {
              setNeedUpdate(true)
            }}
          >
            More
          </Button>
          {/* <Segment title={quote.text} description={quote.author || ''}> */}
          <Segment helpId={null}>
            <FormItems>
              <img
                src={image.url || ''}
                ref={imageRef}
                alt="cat"
                style={{ maxWidth: '100%', height: 'auto', display: 'block' }}
                width={image.width}
                height={image.height}
              />
            </FormItems>
          </Segment>
        </FormItems>
      </div>
    </>
  )
}
