import { zUpdateAssistantTrpcInput } from '@chatai/admin-backend/src/router/assistants/updateAssistant/input'
import { canManageAssistants } from '@chatai/admin-backend/src/utils/can'
import { Alert } from '../../../components/Alert'
import { Button, Buttons } from '../../../components/Button'
import { Checkbox } from '../../../components/Checkbox'
import { FormItems } from '../../../components/FormItems'
import { Input } from '../../../components/Input'
import { RichEditor } from '../../../components/RichEditor'
import { Segment } from '../../../components/Segment'
import { Textarea } from '../../../components/Textarea'
import { UploadToS3 } from '../../../components/UploadToS3'
import { useForm } from '../../../lib/form'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { getEditAssistantRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'

export const AssistantEditPage = withPageWrapper({
  useQuery: () => {
    const { assistantId } = getEditAssistantRoute.useParams()
    return trpc.getAssistant.useQuery({
      id: assistantId,
    })
  },
  setProps: ({ queryResult }) => ({
    assistant: queryResult.data.assistant,
  }),
  checkAccess: ({ ctx }) => canManageAssistants(ctx.me),
  showLoaderOnFetching: false,
  title: ({ assistant }) => `Assistant: ${assistant.name}`,
})(({ assistant }) => {
  const updateAssistant = trpc.updateAssistant.useMutation()
  const { formik, alertProps, buttonProps } = useForm({
    initialValues: {
      id: assistant.id,
      name: assistant.name,
      description: assistant.description,
      bodyMd: assistant.bodyMd,
      imageUrl: assistant.imageUrl,
      hidden: assistant.hidden,
      systemPrompt: assistant.systemPrompt,
      responseText: assistant.responseText,
      chatTitle: assistant.chatTitle,
    },
    validationSchema: zUpdateAssistantTrpcInput,
    onSubmit: async (values) => {
      await updateAssistant.mutateAsync(values)
    },
    resetOnSuccess: false,
    enableReinitialize: true,
    successMessage: 'Assistant updated successfully',
  })

  const deleteAssistant = trpc.deleteAssistant.useMutation()
  const deleteForm = useForm({
    onSubmit: async () => {
      await deleteAssistant.mutateAsync({
        id: assistant.id,
      })
    },
    resetOnSuccess: false,
    enableReinitialize: true,
    successMessage: 'Assistant deleted successfully',
  })
  return (
    <Segment title={`Assistant: ${assistant.name}`} description={assistant.id} helpId="assistant-edit">
      <form onSubmit={formik.handleSubmit}>
        <FormItems>
          <Input label="Name" name="name" formik={formik} />
          <Textarea label="Short Description For User" name="description" formik={formik} />
          <RichEditor label="Long Description For User" name="bodyMd" formik={formik} />
          <UploadToS3 label="Image" name="imageUrl" formik={formik} accept="image/*" />
          <Textarea label="System Prompt" name="systemPrompt" formik={formik} />
          <Textarea
            label="Response Message Text"
            hint="Visible response message from assistant"
            name="responseText"
            formik={formik}
          />
          <Input label="Chat Title" hint="Chat title for new chat" name="chatTitle" formik={formik} />
          <Checkbox label="Hidden" name="hidden" formik={formik} />
          <Alert {...alertProps} />
          <Alert {...deleteForm.alertProps} />
          <Buttons>
            <Button {...buttonProps}>Update Assistant</Button>
            <Button
              {...deleteForm.buttonProps}
              color="red"
              type="button"
              onClick={() => {
                deleteForm.formik.handleSubmit()
              }}
            >
              Delete
            </Button>
          </Buttons>
        </FormItems>
      </form>
    </Segment>
  )
})
