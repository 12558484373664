import { canManageNewsPosts } from '@chatai/admin-backend/src/utils/can'
import InfiniteScroll from 'react-infinite-scroller'
import { Link } from 'react-router-dom'
import { Alert } from '../../../components/Alert'
import { layoutContentElRef } from '../../../components/Layout'
import { Loader } from '../../../components/Loader'
import { Segment } from '../../../components/Segment'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { getEditNewsPostRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'
import css from './index.module.scss'

export const NewsPostsPage = withPageWrapper({
  title: 'News Posts',
  checkAccess: ({ ctx }) => canManageNewsPosts(ctx.me),
})(() => {
  const { data, error, isLoading, isError, hasNextPage, fetchNextPage, isFetchingNextPage, isRefetching } =
    trpc.getNewsPosts.useInfiniteQuery(
      {},
      {
        getNextPageParam: (lastPage) => {
          return lastPage.nextCursor
        },
      }
    )

  return (
    <Segment title="News Posts" helpId="news-posts">
      {isLoading || isRefetching ? (
        <Loader type="section" />
      ) : isError ? (
        <Alert color="red">{error.message}</Alert>
      ) : !data.pages[0].newsPosts.length ? (
        <Alert color="brown">Nothing found</Alert>
      ) : (
        <div className={css.newsPosts}>
          <InfiniteScroll
            threshold={250}
            loadMore={() => {
              if (!isFetchingNextPage && hasNextPage) {
                void fetchNextPage()
              }
            }}
            hasMore={hasNextPage}
            loader={
              <div className={css.more} key="loader">
                <Loader type="section" />
              </div>
            }
            getScrollParent={() => layoutContentElRef.current}
            useWindow={(layoutContentElRef.current && getComputedStyle(layoutContentElRef.current).overflow) !== 'auto'}
          >
            {data.pages
              .flatMap((page) => page.newsPosts)
              .map((newsPost) => (
                <div className={css.newsPost} key={newsPost.serialNumber}>
                  <Segment
                    helpId={null}
                    size={2}
                    title={
                      <Link className={css.newsPostLink} to={getEditNewsPostRoute({ newsPostId: newsPost.id })}>
                        {newsPost.title}
                      </Link>
                    }
                  >
                    Publish Date: {newsPost.publishedAt.toISOString()}
                  </Segment>
                </div>
              ))}
          </InfiniteScroll>
        </div>
      )}
    </Segment>
  )
})
