import { type TrpcRouterOutput } from '@chatai/admin-backend/src/router'
import { zCreateLandingRedirectConfigTrpcInput } from '@chatai/admin-backend/src/router/landingRedirectConfigs/createLandingRedirectConfig/input'
import { zUpdateLandingRedirectConfigTrpcInput } from '@chatai/admin-backend/src/router/landingRedirectConfigs/updateLandingRedirectConfig/input'
import { canManageLandingRedirectConfigs } from '@chatai/admin-backend/src/utils/can'
import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { Alert } from '../../../components/Alert'
import { Button, Buttons } from '../../../components/Button'
import { FormItems } from '../../../components/FormItems'
import { Input } from '../../../components/Input'
import { layoutContentElRef } from '../../../components/Layout'
import { Loader } from '../../../components/Loader'
import { Segment } from '../../../components/Segment'
import { Textarea } from '../../../components/Textarea'
import { useForm } from '../../../lib/form'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { trpc } from '../../../lib/trpc'
import css from './index.module.scss'

export const LandingRedirectPage = withPageWrapper({
  title: 'Landing Redirect Configs',
  checkAccess: ({ ctx }) => canManageLandingRedirectConfigs(ctx.me),
})(() => {
  const { data, error, isLoading, isError, hasNextPage, fetchNextPage, isFetchingNextPage, isRefetching } =
    trpc.getLandingRedirectConfigs.useInfiniteQuery(
      {},
      {
        getNextPageParam: (lastPage) => {
          return lastPage.nextCursor
        },
      }
    )

  return (
    <>
      <Segment title="New Landing Redirect Config" helpId="landing-redirect-configs">
        <div className={css.landingRedirectConfigs}>
          <div className={css.landingRedirectConfig}>
            <LandingRedirectConfigNew />
          </div>
        </div>
      </Segment>
      <Segment title="Existing Landing Redirect Config" helpId={null}>
        {isLoading || isRefetching ? (
          <Loader type="section" />
        ) : isError ? (
          <Alert color="red">{error.message}</Alert>
        ) : !data.pages[0].landingRedirectConfigs.length ? (
          <Alert color="brown">Nothing found</Alert>
        ) : (
          <div className={css.landingRedirectConfigs}>
            <InfiniteScroll
              threshold={250}
              loadMore={() => {
                if (!isFetchingNextPage && hasNextPage) {
                  void fetchNextPage()
                }
              }}
              hasMore={hasNextPage}
              loader={
                <div className={css.more} key="loader">
                  <Loader type="section" />
                </div>
              }
              getScrollParent={() => layoutContentElRef.current}
              useWindow={
                (layoutContentElRef.current && getComputedStyle(layoutContentElRef.current).overflow) !== 'auto'
              }
            >
              {data.pages
                .flatMap((page) => page.landingRedirectConfigs)
                .map((landingRedirectConfig) => (
                  <LandingRedirectConfigEdit
                    key={landingRedirectConfig.id}
                    landingRedirectConfig={landingRedirectConfig}
                  />
                ))}
            </InfiniteScroll>
          </div>
        )}
      </Segment>
    </>
  )
})

export const LandingRedirectConfigNew = () => {
  const createLandingRedirectConfig = trpc.createLandingRedirectConfig.useMutation()
  const trpcUtils = trpc.useUtils()
  const { formik, alertProps, buttonProps } = useForm({
    initialValues: {
      onelinkUrl: '',
      landingUrl: '',
      alias: '',
      searchParamsMatch: '',
      description: '',
    },
    validationSchema: zCreateLandingRedirectConfigTrpcInput,
    onSubmit: async (values) => {
      await createLandingRedirectConfig.mutateAsync(values)
      await trpcUtils.getLandingRedirectConfigs.refetch()
    },
    resetOnSuccess: true,
    enableReinitialize: true,
    successMessage: 'Landing Redirect Config created successfully',
  })
  return (
    <Segment helpId={null}>
      <form onSubmit={formik.handleSubmit}>
        <FormItems>
          <Input
            maxWidth="none"
            label="OneLink URL"
            name="onelinkUrl"
            hint="Example: https://instantai.onelink.me/3xcR"
            formik={formik}
          />
          <Input
            maxWidth="none"
            label="Landing URL"
            name="landingUrl"
            hint="Example: https://m.inai.chat/redirect"
            formik={formik}
          />
          <Input
            maxWidth="none"
            label="Alias"
            name="alias"
            formik={formik}
            hint="Unique name for this config, which will be tracked through appsflyer (af_sub4)"
          />
          <Input
            maxWidth="none"
            label="Search Params Match"
            name="searchParamsMatch"
            hint="Example: utm_ad_id=123&utm_adset_id=234"
            formik={formik}
          />
          <Textarea label="Description" name="description" formik={formik} initialHeight={60} />
          <Alert {...alertProps} />
          <Button {...buttonProps}>Create</Button>
        </FormItems>
      </form>
    </Segment>
  )
}

export const LandingRedirectConfigEdit = ({
  landingRedirectConfig,
}: {
  landingRedirectConfig: TrpcRouterOutput['getLandingRedirectConfigs']['landingRedirectConfigs'][number]
}) => {
  const [deleteAttemptsCount, setDeleteAttemptsCount] = useState(0)
  const [deleteButtonOffset, setDeleteButtonOffset] = useState<{ x: number; y: number }>({ x: 0, y: 0 })
  const deleteButtonText = ['Delete', 'Are you sure?', 'Really?', 'Last chance!'][deleteAttemptsCount]
  const maxDeleteAttemptsCount = 3

  const [restoreAttemptsCount, setRestoreAttemptsCount] = useState(0)
  const [restoreButtonOffset, setRestoreButtonOffset] = useState<{ x: number; y: number }>({ x: 0, y: 0 })
  const restoreButtonText = ['Restore', 'А не надо было удалять 🙂', 'Восстановить?', 'Последний шанс!'][
    restoreAttemptsCount
  ]
  const maxRestoreAttemptsCount = 3

  const [existing, setExisting] = useState<'exists' | 'deleted' | 'restored'>('exists')

  useEffect(() => {
    if (deleteAttemptsCount === 0) {
      setDeleteButtonOffset({
        x: 0,
        y: 0,
      })
      return
    }
    const randomX = Math.random() * 300
    const randomY = Math.random() * -300
    setDeleteButtonOffset({
      x: randomX,
      y: randomY,
    })
  }, [deleteAttemptsCount])

  useEffect(() => {
    if (restoreAttemptsCount === 0) {
      setRestoreButtonOffset({
        x: 0,
        y: 0,
      })
      return
    }
    const randomX = Math.random() * 300
    const randomY = Math.random() * -300
    setRestoreButtonOffset({
      x: randomX,
      y: randomY,
    })
  }, [restoreAttemptsCount])

  const updateLandingRedirectConfig = trpc.updateLandingRedirectConfig.useMutation()
  const { formik, alertProps, buttonProps } = useForm({
    initialValues: {
      onelinkUrl: landingRedirectConfig.onelinkUrl,
      landingUrl: landingRedirectConfig.landingUrl,
      alias: landingRedirectConfig.alias,
      searchParamsMatch: landingRedirectConfig.searchParamsMatch,
      description: landingRedirectConfig.description,
    },
    validationSchema: zUpdateLandingRedirectConfigTrpcInput.shape.data,
    onSubmit: async (values) => {
      await updateLandingRedirectConfig.mutateAsync({
        id: landingRedirectConfig.id,
        data: values,
      })
    },
    resetOnSuccess: false,
    enableReinitialize: true,
    successMessage: 'Landing Redirect Config updated successfully',
  })

  const deleteLandingRedirectConfig = trpc.deleteLandingRedirectConfig.useMutation()
  const deleteForm = useForm({
    onSubmit: async () => {
      await deleteLandingRedirectConfig.mutateAsync({
        id: landingRedirectConfig.id,
      })
      setExisting('deleted')
    },
    resetOnSuccess: false,
    enableReinitialize: true,
    successMessage: 'Landing Redirect Config deleted successfully',
  })
  return (
    <div className={css.landingRedirectConfig}>
      <Segment title={`Config #${landingRedirectConfig.serialNumber}`} helpId={null}>
        <form onSubmit={formik.handleSubmit}>
          <FormItems>
            <Input maxWidth="none" label="OneLink URL" name="onelinkUrl" formik={formik} />
            <Input maxWidth="none" label="Landing URL" name="landingUrl" formik={formik} />
            <Input maxWidth="none" label="Alias" name="alias" formik={formik} />
            <Input maxWidth="none" label="Search Params Match" name="searchParamsMatch" formik={formik} />
            <Textarea label="Description" name="description" formik={formik} initialHeight={60} />
            <Alert {...alertProps} />
            <Alert {...deleteForm.alertProps} />
            {existing === 'exists' ? (
              <Buttons>
                <Button {...buttonProps}>Update</Button>
                <Button
                  {...deleteForm.buttonProps}
                  color="red"
                  type="button"
                  style={{
                    transition: 'transform 0.3s',
                    transform: `translate(${deleteButtonOffset.x}px, ${deleteButtonOffset.y}px)`,
                  }}
                  onClick={() => {
                    if (deleteAttemptsCount < maxDeleteAttemptsCount) {
                      setDeleteAttemptsCount(deleteAttemptsCount + 1)
                    } else {
                      setDeleteAttemptsCount(0)
                      deleteForm.formik.handleSubmit()
                    }
                  }}
                >
                  {deleteButtonText}
                </Button>
              </Buttons>
            ) : existing === 'deleted' ? (
              <Buttons>
                <Button
                  color="green"
                  type="button"
                  style={{
                    transition: 'transform 0.3s',
                    transform: `translate(${restoreButtonOffset.x}px, ${restoreButtonOffset.y}px)`,
                  }}
                  onClick={() => {
                    if (restoreAttemptsCount < maxRestoreAttemptsCount) {
                      setRestoreAttemptsCount(restoreAttemptsCount + 1)
                    } else {
                      setRestoreAttemptsCount(0)
                      alert(
                        'Это функция на данный момент не реализована. Пожалуйста, скоипруйте данные из полей и создайте новую конфигурацию. По сути получится то же самое, что и восстановление'
                      )
                      setExisting('restored')
                    }
                  }}
                >
                  {restoreButtonText}
                </Button>
              </Buttons>
            ) : null}
          </FormItems>
        </form>
      </Segment>
    </div>
  )
}
